import $ from "jQuery";

/*--------------------------------------------------------
Akkordeon
--------------------------------------------------------*/
$(".tab-category-element").on("click",function(){
	if($(this).hasClass('active')) {
		$(".tab-category-element").each(function( index ) {
            $(this).removeClass('active');
            $(this).find('.close-container').removeClass('rotate');
		});
	} else {
		$(".tab-category-element").each(function( index ) {
            $(this).removeClass('active');
            $(this).find('.close-container').removeClass('rotate');
		});
		$(this).addClass('active');
		$(this).find('.close-container').addClass('rotate');
	}
	$(".tab-category-element").each(function( index ) {
        if($(this).hasClass('active')) {
            $(this).next().slideDown();
            $(this).find('.close-container').addClass('rotate');
        } else {
            $(this).next().slideUp();
        }
	});
});