import $ from "jQuery";
var jQuery = $;

function isNumber(str) {
    if (typeof str != "string") return false // we only process strings!
    // could also coerce to string: str = ""+str
    return !isNaN(str) && !isNaN(parseFloat(str))
}

function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height()/2;
    return (/*(elemBottom <= docViewBottom) &&*/ (elemTop <= docViewBottom));
}

$( document ).ready(function() {
    setTimeout(function() {
        $(".fade").each(function(index) {
            if(isScrolledIntoView(this)){
                if($(this).hasClass('ce_rsce_fakten') && !$(this).hasClass('is-visible')){
                    $($(this).find(".zahl")).each(function(index) {
                        var test = $(this);
                        if(isNumber(test.text())){
                            jQuery({ Counter: 0 }).animate({ Counter: test.text() }, {
                                duration: 4000,
                                easing: 'swing',
                                step: function () {
                                    test.text(Math.ceil(this.Counter));
                                }
                            });
                        }
                        
                    });
                    $(this).addClass("is-visible");
                }
                if(!$(this).hasClass('ce_rsce_fakten') && !$(this).hasClass('is-visible')){
                    $(this).addClass("is-visible");
                }
            }
        })
    },500);
    window.addEventListener("scroll", function(e) {
        $(".fade").each(function(index) {
            if(isScrolledIntoView(this)){
                if($(this).hasClass('ce_rsce_fakten') && !$(this).hasClass('is-visible')){
                    $($(this).find(".zahl")).each(function(index) {
                        var test = $(this);
                        if(isNumber(test.text())){
                            jQuery({ Counter: 0 }).animate({ Counter: test.text() }, {
                                duration: 4000,
                                easing: 'swing',
                                step: function () {
                                    test.text(Math.ceil(this.Counter));
                                }
                            });
                        }
                    });
                    $(this).addClass("is-visible");
                }
                if(!$(this).hasClass('ce_rsce_fakten') && !$(this).hasClass('is-visible')){
                    $(this).addClass("is-visible");
                }
            }
        });
    });
});