import $ from "jQuery";

/*--------------------------------------------------------
	Mobile Navi
--------------------------------------------------------*/
;(function(e) {
    e(function() {
        e(".navigation").prepend(e('<div class="nav-mobile"><button type="button" role="button" aria-label="Toggle Navigation" class="lines-button x"><span class="lines"></span></button></div>'));
        e(".nav-item").has("ul").prepend('<span class="nav-click"><i class="icon-down-open-big"></i></span>');
        e(".nav-mobile button").click(function() {
            e("#header").toggleClass('menu-offen');
            e(".nav-item.sibling.last").removeClass("sibling");
            if(!e(".nav-list").hasClass('offen')){
                setTimeout(function(){
                    e(".nav-list").slideToggle();
                },100);
            } else {
                e(".nav-list").fadeOut('fast');
            }
            e(".nav-list").toggleClass("offen");
            
            
            e(".navigation-background").slideToggle('fast');
            e(".nav-mobile").toggleClass("offen");
            e(".nav-mobile,.nav-mobile button").toggleClass("close")
        });
        e(".nav-list").on("click", ".nav-click", function() {
            e(this).siblings(".nav-submenu").toggle();
            e(this).toggleClass("nav-rotate");
        });
    })
})($);

window.onscroll = function () {
    check_header();
};
$( document ).ready(function() {
    check_header();
});

function check_header(){
    if(window.pageYOffset > 100){
        $("#header").addClass('shrinked');
        //$("#header .inside-top").slideUp();
    } else {
        $("#header").removeClass('shrinked');
        //$("#header .inside-top").slideDown();
    }
}
