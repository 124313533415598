import $ from "jquery";
require("svgfallback");
require("blockmapsfonts");
require("respimage/respimage");

require("./navigation.js");
require("./map.js");
require("./akkordeon.js");
require('./rsce_text_mit_bild.js');
require('./isScrolledIntoView.js');
require('./slickslider.min.js');
// require("./lazyload.js");
require("./responsive-background-images.js");
// require("./gleichehoehe.js");
// require("./smoothscrolling.js");
// require("./swipebox.min.js");



$('.ce_rsce_teaser.is-slider ul.teaserslider').slick({
    arrows: true,
    prevArrow:'<span class="prev"><i class="icon-left-open-big"></i></span>',
	nextArrow:'<span class="next"><i class="icon-right-open-big"></i></span>',
    infinite: true,
    speed: 300,
    autoplay:false, 
	autoplaySpeed: 4000,
	speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
});

$('.ce_rsce_referenzen_slider ul').slick({
    false: true,
    arrows: true,
    prevArrow:'<span class="prev"><i class="icon-left-open-big"></i></span>',
	nextArrow:'<span class="next"><i class="icon-right-open-big"></i></span>',
    infinite: true,
    speed: 300,
    autoplay:true, 
	autoplaySpeed: 4000,
	speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true
});

$('.ce_rsce_partner_slider ul.partner-slider').slick({
	arrows:true,
	prevArrow:'<span class="prev"><i class="icon-left-open-big"></i></span>',
	nextArrow:'<span class="next"><i class="icon-right-open-big"></i></span>',
	slidesToShow: 5, 
	variableWidth: false,
	lazyLoad:'ondemand',
	autoplay:true, 
	autoplaySpeed: 3000,
	dots: false,
	speed: 500,
	responsive: [
		{
            breakpoint: 1024,
            settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            
            }
        },
        {
            breakpoint: 900,
            settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            
            }
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            
            }
		}
	]
});